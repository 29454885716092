<template>
  <div class="add-installment-program">
    <KTCodePreview v-bind:title="title">
      <template v-slot:preview>
        <b-form ref="form" lazy-validation class="row">
          <b-container>
            <b-row>
              <b-col cols="3">
                <b-form-group class="required-control">
                  <label>Tên chương trình:</label>
                  <b-form-input
                    size="sm"
                    v-model="$v.mainModel.name.$model"
                    placeholder="Nhập tên"
                    autocomplete="off"
                    :state="validateState('name')"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    v-if="validateState('name', 'required')"
                    >Vui lòng tên chương trình</b-form-invalid-feedback
                  >
                  <b-form-invalid-feedback
                    v-if="validateState('name', 'minLength')"
                    >Vui lòng nhập kí tự nhiều hơn 4
                  </b-form-invalid-feedback>
                  <b-form-invalid-feedback
                    v-if="validateState('name', 'maxLength')"
                    >Vui lòng nhập kí tự không quá 255
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group class="required-control">
                  <label>Mã chương trình:</label>
                  <b-form-input
                    size="sm"
                    v-model="$v.mainModel.code.$model"
                    placeholder="Nhập mã"
                    autocomplete="off"
                    :state="validateState('code')"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    v-if="validateState('code', 'required')"
                    >Vui lòng nhập mã chương trình</b-form-invalid-feedback
                  >
                  <b-form-invalid-feedback
                    v-if="validateState('code', 'minLength')"
                    >Vui lòng nhập kí tự nhiều hơn 4
                  </b-form-invalid-feedback>
                  <b-form-invalid-feedback
                    v-if="validateState('code', 'maxLength')"
                    >Vui lòng nhập kí tự không quá 100
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group>
                  <label>Ngày bắt đầu:</label>
                  <div>
                    <date-picker
                      placeholder="Từ ngày"
                      class="form-control form-control-sm"
                      :config="dpConfigs.date"
                      v-model="$v.mainModel.startDate.$model"
                      autocomplete="off"
                    ></date-picker>
                  </div>
                  <b-form-invalid-feedback :state="validateState('startDate')"
                    >Vui lòng nhập ngày bắt đầu</b-form-invalid-feedback
                  >
                </b-form-group>

                <b-form-group>
                  <label>Ngày kết thúc:</label>
                  <date-picker
                    placeholder="Đến ngày"
                    class="form-control form-control-sm"
                    :config="dpConfigs.date"
                    v-model="mainModel.endDate"
                    autocomplete="off"
                  ></date-picker>
                </b-form-group>
              </b-col>

              <b-col cols="5">
                <b-form-group>
                  <label>Mô tả:</label>
                  <b-form-textarea
                    class="input-style"
                    v-model="$v.mainModel.description.$model"
                    placeholder="Nhập mô tả"
                    rows="5"
                    autocomplete="off"
                    :state="validateState('description')"
                  ></b-form-textarea>
                  <b-form-invalid-feedback
                    v-if="validateState('description', 'maxLength')"
                    >Vui lòng nhập kí tự không quá 255
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col cols="3">
                <b-form-group>
                  <label>Trạng thái:</label>
                  <b-form-select
                    size="sm"
                    class="select-style"
                    v-model="mainModel.status"
                    :options="[
                      {
                        id: true,
                        name: 'Hoạt động',
                      },
                      {
                        id: false,
                        name: 'Ngừng hoạt động',
                      },
                    ]"
                    value-field="id"
                    text-field="name"
                    autocomplete="off"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col cols="2"></b-col>
            </b-row>

            <hr
              class="hr-text"
              data-content="Cấu hình trả góp"
              style="font-weight: 600"
            />
            <b-row>
              <b-col cols="12">
                <b-form-group>
                  <label>Kỳ hạn:</label>
                  <b-col md="4" class="p-0">
                    <treeselect
                      :options="optionsMonth"
                      v-model="mainModel.monthNo"
                      :multiple="true"
                      :backspace-removes="false"
                      placeholder="Chọn kỳ hạn"
                      :max-height="200"
                      noResultsText="Không tìm thấy kết quả"
                      retryTitle="Thử lại"
                    ></treeselect>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group>
                  <label>Chương trình trả góp:</label>
                  <b-col md="4" class="p-0">
                    <vue-autosuggest
                      :suggestions="optionInstallment"
                      v-model="mainModel.installmentProgramName"
                      :limit="100"
                      :input-props="{
                        class: 'autosuggest__input',
                        placeholder: 'Nhập dịch vụ trả góp',
                      }"
                      @input="onInputed($event, 'Form')"
                      @selected="onSelected($event, 'InstallmentProgram')"
                    >
                      <div slot-scope="{ suggestion }">
                        {{ suggestion.item.name }}
                      </div>
                    </vue-autosuggest>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <b-table
                  bordered
                  hover
                  :fields="installmentFields"
                  :items="installments"
                  :per-page="10"
                  :current-page="currentPageInstallmentTable"
                >
                  <template v-slot:cell(actions)="row">
                    <v-icon
                      small
                      class="text-danger"
                      @click="deleteItem('installment', row.item.id)"
                      v-b-tooltip
                      title="Xóa"
                      >mdi-delete</v-icon
                    >
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <b-row>
                  <b-col>
                    <p
                      class="font-weight-bolder pl-1"
                      v-if="installments.length"
                    >
                      Tổng số :
                      {{ convertPrice(installments.length) }}
                    </p>
                  </b-col>
                  <b-col>
                    <b-pagination
                      v-show="installments.length > 10"
                      v-model="currentPageInstallmentTable"
                      :total-rows="installments.length"
                      :per-page="10"
                      align="right"
                    ></b-pagination>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <div class="my-5"></div>

            <hr
              class="hr-text"
              data-content="Cấu hình sản phẩm"
              style="font-weight: 600"
            />

            <b-row>
              <b-col md="4">
                <b-form-group label="Danh mục">
                  <treeselect
                    :options="categories"
                    v-model="selectedCategory"
                    :multiple="true"
                    :normalizer="normalizer"
                    :backspace-removes="false"
                    @deselect="showDeleteCategoryAlert"
                    placeholder="Chọn danh mục"
                    :max-height="200"
                    noResultsText="Không tìm thấy kết quả"
                    valueFormat="object"
                    :clearable="false"
                  ></treeselect>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-form-group class="col-md-12 pr-0">
                <label>Tìm sản phẩm:</label>
                <b-col md="12" class="row pr-0">
                  <b-col md="6" class="p-0">
                    <vue-autosuggest
                      v-model="searchProductFor"
                      :suggestions="optionProducts"
                      @selected="onSelectedProductFor"
                      :limit="10"
                      @input="onInputChangeProductFor"
                      :input-props="{
                        class: 'autosuggest__input',
                        placeholder: 'Nhập tên sản phẩm',
                        style: 'border-radius:0px!important',
                      }"
                      :should-render-suggestions="
                        (size, loading) =>
                          size >= 0 && !loading && searchProductFor.length > 0
                      "
                    >
                      <template slot-scope="{ suggestion }">
                        <div>
                          {{ suggestion.item.productName }}
                        </div>
                      </template>
                    </vue-autosuggest>
                  </b-col>
                  <b-col md="2" class="p-0">
                    <b-form-select
                      class="
                        select-style
                        border-left-0
                        rounded-left-0 rounded-right-0
                      "
                      v-model="selectTypeSearchFor"
                      :options="[
                        {
                          id: 1,
                          name: 'Sản phẩm IMEI',
                        },
                        {
                          id: 2,
                          name: 'Sản phẩm',
                        },
                      ]"
                      size="sm"
                      value-field="id"
                      text-field="name"
                      disabled-field="notEnabled"
                      @change="debounceInputFor"
                    ></b-form-select>
                  </b-col>
                  <b-col md="2" class="p-0">
                    <b-form-select
                      class="select-style border-left-0 rounded-left-0"
                      v-model="selectTypeOfProductFor"
                      :options="productTypes"
                      size="sm"
                      value-field="id"
                      text-field="name"
                      disabled-field="notEnabled"
                      @change="debounceInputFor"
                    ></b-form-select>
                  </b-col>
                  <b-col md="2" class="pr-0">
                    <div class="d-flex justify-content-end">
                      <button
                        type="button"
                        class="btn btn-secondary font-weight-bolder btn-sm"
                        @click="showModalImportProductFor"
                      >
                        <i
                          style="font-size: 1rem"
                          class="far fa-file-excel"
                        ></i>
                        Nhập từ excel
                      </button>
                    </div>
                  </b-col>
                </b-col>
              </b-form-group>
            </b-row>
            <b-row>
              <b-col cols="12">
                <div>
                  <b-table
                    bordered
                    hover
                    class="col-md-12"
                    :fields="productfields"
                    :items="products"
                    :per-page="10"
                    :current-page="currentPageProductTable"
                  >
                    <template v-slot:cell(productName)="row">
                      <span class="d-block"> {{ row.item.productCode }}</span>
                      <b class="d-block">{{ row.item.productName }}</b>
                    </template>
                    <template v-slot:cell(originalPrice)="row">
                      <span class="text-right">{{
                        convertPrice(row.item.originalPrice)
                      }}</span>
                    </template>
                    <template v-slot:cell(sellingPrice)="row">
                      <span class="text-right">{{
                        convertPrice(row.item.sellingPrice)
                      }}</span>
                    </template>
                    <template v-slot:cell(listedPrice)="row">
                      <span class="text-right">{{
                        convertPrice(row.item.listedPrice)
                      }}</span>
                    </template>
                    <template v-slot:cell(discountAmount)="row">
                      <span class="text-right">
                        <b-form-input
                          size="sm"
                          class="text-right"
                          v-model="row.item.discountAmount"
                          placeholder="Nhập số tiền giảm giá"
                          autocomplete="off"
                        ></b-form-input
                      ></span>
                    </template>
                    <template v-slot:cell(actions)="row">
                      <v-icon
                        small
                        class="text-danger"
                        @click="showDeleteProductAlert(row.item)"
                        v-b-tooltip
                        title="Xóa"
                        >mdi-delete</v-icon
                      >
                    </template>
                  </b-table>
                  <b-pagination
                    v-show="products.length > 10"
                    v-model="currentPageProductTable"
                    :total-rows="products.length"
                    :per-page="10"
                    align="right"
                  ></b-pagination>
                </div>
              </b-col>
              <b-col cols="6" md="4"></b-col>
            </b-row>
          </b-container>
        </b-form>
        <b-modal
          ref="import-product-for-modal"
          hide-footer
          title="Thêm sản phẩm từ Excel"
          id="modal-prevent-closing"
          size="lg"
        >
          <b-row>
            <b-col>
              <div class="form-group">
                <span>
                  <b>Bước 1:</b>
                  Vui lòng chuẩn bị dữ liệu mẫu từ file excel đúng format, hoặc
                  có thể tải ở
                  <a :href="urlExcelFor">đây</a>
                </span>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <span>
                <b>Bước 2:</b>
                Tiến hành Import
              </span>
              <div style="padding-top: 15px">
                <b-form-file
                  placeholder="Hãy chọn một tập tin"
                  @change="selectFileFor"
                  v-model="fileFor"
                  ref="file-input"
                  class="mb-2"
                ></b-form-file>
              </div>
            </b-col>
          </b-row>
          <b-container class="mt-4 pl-0 pr-0" v-show="importItemFor.length > 0">
            <h5>
              Danh sách sản phẩm từ file excel( Tổng:
              {{ importItemFor.length }} sản phẩm)
            </h5>
            <b-table
              id="my-table"
              class="myTable"
              responsive
              bordered
              hover
              :fields="fieldImportFor"
              :items="importItemFor"
              :per-page="10"
              :current-page="currentPageFor"
            >
              <template v-slot:cell(originalPrice)="row">
                <div style="text-align: end">
                  <span v-text="convertPrice(row.item.originalPrice)"></span>
                </div>
              </template>

              <template v-slot:cell(sellingPrice)="row">
                <div style="text-align: end">
                  <span v-text="convertPrice(row.item.sellingPrice)"></span>
                </div>
              </template>
              <template v-slot:cell(returnPrice)="row">
                <div style="text-align: end">
                  <span v-text="convertPrice(row.item.returnPrice)"></span>
                </div>
              </template>
            </b-table>
            <b-pagination
              v-show="importItemFor.length > 10"
              v-model="currentPageFor"
              :total-rows="rowsFor"
              :per-page="10"
              aria-controls="my-table"
            ></b-pagination>
          </b-container>

          <b-button
            style="fontweight: 600; width: 70px"
            variant="primary"
            size="sm"
            @click="validDataFor"
            >Lưu</b-button
          >
          <b-button
            style="margin-left: 10px; font-weight: 600; width: 70px"
            variant="secondary"
            size="sm"
            @click="$refs['import-product-for-modal'].hide()"
          >
            Hủy
          </b-button>
        </b-modal>
      </template>
      <template v-slot:foot>
        <!-- <b-col lg="4" class="pb-2"> -->
        <b-container>
          <b-row>
            <b-overlay
              :show="isBusy"
              opacity="0.6"
              spinner-variant="white"
              spinner-type="grow"
              spinner-small
            >
              <b-button
                style="fontweight: 600; width: 70px"
                variant="primary"
                size="sm"
                type="submit"
                @click="saveData"
                >Lưu</b-button
              >
            </b-overlay>

            <b-button
              style="margin-left: 10px; font-weight: 600; width: 70px"
              variant="secondary"
              size="sm"
              @click="onBack"
              >Hủy</b-button
            >
          </b-row>
        </b-container>
      </template>
    </KTCodePreview>
  </div>
</template>
<style>
.input-style {
  border: 1px solid #ced4da;
}
input.form-control {
  border: 1px solid #ced4da;
}
.muti-basic-custom .rounded {
  border-radius: 0.28rem !important;
}
.multi-select-wrapper {
  flex: 1 1 auto;
}
.center-label label {
  margin-bottom: 0px;
}

.select-style {
  border-radius: 0.28rem;
  box-shadow: none;
  border: 1px solid #ced4da;
}

.autosuggest__results {
  position: absolute;
  width: 100%;
  z-index: 1;
  max-height: 200px;
  border: 1px solid #e0e0e0;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #fff;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

.autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.35em + 1.1rem + 2px);
  line-height: 1.35;
  padding: 0.55rem 0.75rem;
  font-weight: 400;
  font-size: 0.925rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.28rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import Swal from 'sweetalert2';
import datePicker from 'vue-bootstrap-datetimepicker';
import moment from 'moment';
import { validationMixin } from 'vuelidate';
import { required, maxLength, minLength } from 'vuelidate/lib/validators';
import { cmdUrl } from '../../../utils/apiUrl';
import { getToastInstance } from '../../../utils/toastHelper';
import KTUtil from '@/assets/js/components/util';

import decounce from 'debounce';
import {
  TIME_TRIGGER,
  URL_IMPORT_PRODUCT_PROMOTION,
  URL_IMPORT_PRODUCT_FOR_PROMOTION,
} from '../../../utils/constants';
import xlsx from 'xlsx';
import {
  currencyMask,
  makeToastFaile,
  unMaskPrice,
  makeToastSuccess,
  convertPrice,
  cloneDeep,
} from '../../../utils/common';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
export default {
  mixins: [validationMixin],
  data() {
    return {
      title: '',
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
          keepInvalid: true,
        },
      },
      filteredInstallments: [],
      selectedCategory: [],
      currentPageInstallmentTable: 1,
      installments: [],
      installmentFields: [
        {
          key: 'name',
          label: 'Tên trả góp',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'code',
          label: 'Mã trả góp',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'name',
          label: 'Tên trả góp',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        { key: 'actions', label: '' },
      ],
      mainModel: {
        id: 0,
        code: null,
        name: null,
        description: null,
        startDate: moment().format('DD/MM/YYYY'),
        endDate: null,
        products: [],
        appliedCategories: [],
        appliedProducts: [],
        status: true,
        installmentProgramName: null,
        monthNo: [],
      },
      productfields: [
        {
          key: 'productName',
          label: 'Tên',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
          },
          tdClass: 'text-left align-middle',
        },
        {
          key: 'originalPrice',
          label: 'Giá nhập',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
          },
          tdClass: 'text-right align-middle',
        },
        {
          key: 'listedPrice',
          label: 'Giá niêm yết',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
          },
          tdClass: 'text-right align-middle',
        },
        {
          key: 'discountAmount',
          label: 'Chiết khấu',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
          },
          tdClass: 'text-right align-middle',
        },
        { key: 'actions', label: '', tdClass: 'text-center align-middle' },
      ],
      filteredProducts: [],
      products: [],
      searchProductFor: '',
      categories: [],
      isSearching: false,
      productTypes: [
        {
          id: 0,
          name: 'Tất cả sản phẩm',
        },
        {
          id: 1,
          name: 'Sản phẩm cha',
        },
        {
          id: 2,
          name: 'Sản phẩm con',
        },
        {
          id: 3,
          name: 'Sản phẩm độc lập',
        },
        {
          id: 4,
          name: 'Sản phẩm cha + độc lập',
        },
        {
          id: 5,
          name: 'Sản phẩm con + độc lập',
        },
      ],
      selectTypeSearchFor: 1,
      selectTypeOfProductFor: 0,
      importItems: [],
      file: null,
      excellist: [],
      fieldImport: [
        {
          key: 'productCode',
          label: 'Mã sản phẩm',
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
        },
      ],
      importItemFor: [],
      fileFor: null,
      excellistFor: [],
      currentPageFor: 1,
      fieldImportFor: [
        {
          key: 'productCode',
          label: 'Mã sản phẩm',
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
        },
        {
          key: 'discountAmount',
          label: 'Chiết khấu',
        },
      ],
      urlExcel: URL_IMPORT_PRODUCT_PROMOTION,
      urlExcelFor: URL_IMPORT_PRODUCT_FOR_PROMOTION,
      currentPageProductTable: 1,
      mask: currencyMask,
      installmentProgramId: null,
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        };
      },
      isBusy: false,
      optionsMonth: ['3', '6', '9', '12', '24'].map((i) => ({
        id: i,
        label: `${i} Tháng`,
      })),
      selectMonth: [],
    };
  },
  validations: {
    mainModel: {
      code: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(100),
      },
      startDate: {
        required,
      },
      name: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(255),
      },
      description: {
        maxLength: maxLength(255),
      },
    },
  },
  components: {
    KTCodePreview,
    datePicker,
    Treeselect,
  },
  watch: {},
  computed: {
    rows() {
      return this.importItems.length;
    },
    rowsFor() {
      return this.importItemFor.length;
    },
    optionInstallment() {
      return [{ data: cloneDeep(this.filteredInstallments) }];
    },
    optionProducts() {
      return [{ data: cloneDeep(this.filteredProducts) }];
    },
  },
  mounted() {
    this.title = 'Tạo chương trình trả góp';
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.title }]);
  },
  created() {
    this.fetchInstallments();
    this.getCategories();
    if (this.$route.params.id) {
      this.installmentProgramId = this.$route.params.id || null;
      this.getInstallmentProgramById();
    }
  },
  methods: {
    ...getToastInstance(),
    convertPrice,
    validateState(name, errorName) {
      if (!name) {
        return null;
      }
      if (!errorName) {
        const { $dirty, $error } = this.$v.mainModel[name];

        return $dirty ? !$error : null;
      }
      return !this.$v.mainModel[name][errorName];
    },
    onSelected({ item }, type) {
      switch (type) {
        case 'InstallmentProgram':
          {
            const index = this.installments.findIndex(
              (installment) => installment.id === item.id
            );

            if (index > -1) {
              makeToastFaile(`${item.name} đã tồn tại`);
            } else {
              this.installments.push(item);
              this.mainModel.installmentProgramId = item.id;
            }
          }
          break;
        default:
          break;
      }
    },
    getInstallmentProgramById: function () {
      ApiService.query(
        cmdUrl.InstallmentProgram.byId.replace(
          '{id}',
          this.installmentProgramId
        )
      ).then((response) => {
        const data = response.data.data;
        this.mainModel = data;
        this.installments = data.installments;

        const existProducts = data.products.map((element) => {
          element.exist = true;
          return element;
        });

        this.products = existProducts;

        this.categories = this.categories.map((category) => {
          if (data.categories.map((item) => item.id).includes(category.id)) {
            category.exist = true;
          }
          return category;
        });

        this.selectedCategory = data.categories;
      });
    },
    fetchInstallments: function () {
      ApiService.query('/installments', {
        params: {
          page: 1,
          pageSize: 50,
        },
      }).then((response) => {
        const installments = response.data.data.result;
        this.filteredInstallments = installments;
      });
    },
    getCategories() {
      ApiService.query('category')
        .then(({ data }) => {
          if (!data.status) {
            return this.makeToastFaile('Lấy dữ liệu danh mục thất bại!');
          }
          this.categories = data.data.list_caterogy;
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    showDeleteCategoryAlert: function (item) {
      if (!item.exist) {
        return;
      }
      Swal.fire({
        title: 'Xóa sản phẩm!',
        text: `Bạn có chắc muốn xóa ${item.name} này không ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      }).then(async (result) => {
        if (result.dismiss != 'cancel') {
          if (this.installmentProgramId) {
            const data = await this.checkDeletedItemProduct(item.id, 2).catch(
              () => {
                Swal.fire({
                  title: 'Xoá không thành công',
                  icon: 'error',
                  text: 'Lỗi không thể xoá danh mục ?',
                  confirmButtonColor: 'rgb(221, 51, 51)',
                  confirmButtonText: 'Xác nhận',
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown',
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp',
                  },
                });
                this.selectedCategory.push(item);
              }
            );
            if (data.length > 0) {
              Swal.fire({
                title: 'Không thể xoá!',
                icon: 'error',
                text: 'Danh mục sản phầm đang được cấu hình trong đơn hàng hoặc hoá đơn.',
                confirmButtonColor: 'rgb(221, 51, 51)',
                confirmButtonText: 'Xác nhận',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp',
                },
              });
              this.selectedCategory.push(item);
              return;
            }

            const index = this.categories.findIndex(
              (category) => category.id === item.id
            );
            if (index > -1) {
              this.categories[index].exist = false;
            }
          }
        } else {
          this.selectedCategory.push(item);
        }
      });
    },
    showDeleteProductAlert: function (item) {
      if (!item.exist) {
        this.deleteItem('product', item.productId);
        return;
      }
      Swal.fire({
        title: 'Xóa sản phẩm!',
        text: `Bạn có chắc muốn xóa ${item.productName} này không ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      }).then(async (result) => {
        if (result.dismiss != 'cancel') {
          if (this.installmentProgramId) {
            const data = await this.checkDeletedItemProduct(
              item.productId,
              1
            ).catch(() => {
              Swal.fire({
                title: 'Xoá không thành công',
                icon: 'error',
                text: 'Lỗi không thể xoá danh mục ?',
                confirmButtonColor: 'rgb(221, 51, 51)',
                confirmButtonText: 'Xác nhận',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp',
                },
              });
            });
            if (data.length > 0) {
              Swal.fire({
                title: 'Không thể xoá!',
                icon: 'error',
                text: 'Sản phầm đang được cấu hình trong đơn hàng hoặc hoá đơn .',
                confirmButtonColor: 'rgb(221, 51, 51)',
                confirmButtonText: 'Xác nhận',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp',
                },
              });
              return;
            }
          }
          this.deleteItem('product', item.productId);
        }
      });
    },
    checkDeletedItemProduct(refId, refType) {
      const data = {
        refId: refId,
        refType: refType,
      };
      return ApiService.patch(
        `installment-programs/${this.installmentProgramId}/product`,
        {
          ...data,
        }
      ).then((response) => response.data.data);
    },
    deleteItem(type, id) {
      let items = [];
      let prop = null;
      if (type === 'installment') {
        items = this.installments;
        prop = 'id';
      } else if (type === 'product') {
        items = this.products;
        prop = 'productId';
      }

      if (items.length && prop) {
        const index = items.findIndex((item) => item[prop] === id);
        if (index > -1) {
          items.splice(index, 1);
        }
      }
    },
    fetchProduct(textSearch) {
      this.isSearching = true;
      this.filteredProducts = [];
      const params = {
        searchProduct: textSearch ? textSearch.trim() : null,
        typeSearch: this.selectTypeSearchFor,
        typeOfProduct: this.selectTypeOfProductFor,
      };
      ApiService.query(`productSearch/search-by-type`, {
        params,
      }).then((response) => {
        const { data } = response.data;
        this.filteredProducts = data.filter((product) => {
          return ![3, 4].includes(product.productType);
        });
        this.isSearching = false;
      });
    },
    onSelectedProductFor(option) {
      this.searchProductFor = '';
      const index = this.products.findIndex(
        (product) => product.productId === option.item.productId
      );
      if (index > -1) {
        return makeToastFaile(`${option.item.productName} đã tồn tại`);
      }
      this.products.push({
        productId: option.item.productId,
        productName: option.item.productName,
        productCode: option.item.productCode,
        originalPrice: Number(option.item.originalPrice) || 0,
        sellingPrice: Number(option.item.sellingPrice) || 0,
        listedPrice: Number(option.item.listedPrice) || 0,
        discountAmount: 0,
      });
    },
    onInputChangeProductFor(text) {
      this.searchProductFor = text;
      this.debounceInputFor();
    },
    onBack: function () {
      history.back();
    },
    initDatacreate() {
      this.installments.forEach((element) => {
        element.importPrice = unMaskPrice(element.importPrice || 0);
      });

      const params = cloneDeep(this.mainModel);
      const monthNo = params.monthNo ? params.monthNo.join() : null;
      return {
        name: params.name,
        code: params.code,
        description: params.description,
        status: params.status,
        startDate: params.startDate
          ? moment(params.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
          : null,
        endDate: params.endDate
          ? moment(params.endDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
        installments: this.installments,
        products: this.products,
        categories: this.selectedCategory,
        monthNo: monthNo,
      };
    },
    saveData: async function () {
      this.$v.mainModel.$touch();
      if (this.$v.mainModel.$anyError) {
        KTUtil.scrollTop();
        return;
      }
      this.isBusy = true;
      const params = this.initDatacreate();

      let method = 'post';
      if (this.installmentProgramId) {
        params.id = Number(this.installmentProgramId) || null;
        method = 'put';
      }

      ApiService[method](cmdUrl.InstallmentProgram.root, params)
        .then((response) => {
          this.isBusy = false;
          const { status, message } = response.data;
          if (status === 1) {
            makeToastSuccess(message);
            history.back();
          } else {
            makeToastFaile(message);
          }
        })
        .catch((err) => {
          this.isBusy = false;
          const message = err.response.data.message;
          makeToastFaile(message);
        });
    },
    debounceInputFor: decounce(function () {
      if (!this.isSearching) {
        let textSearch = this.searchProductFor;
        this.fetchProduct(textSearch);
      }
    }, TIME_TRIGGER),
    selectFile(event) {
      this.importItems = [];
      const files = event.target.files[0];
      this.file = files;
      if (!files) {
        return;
      } else if (!/\.(xls|xlsx)$/.test(files.name.toLowerCase())) {
        this.file = null;
        this.clearFiles();
        return alert(
          'Định dạng của file tải lên không chính xác. Vui lòng tải lên file có đuôi .xls hoặc .xlsx'
        );
      }
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const XLSX = xlsx;
          const workbook = XLSX.read(data, {
            type: 'binary',
          });
          const wsname = workbook.SheetNames[0]; // Take the first sheet，wb.SheetNames[0] :Take the name of the first sheet in the sheets
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            blankRows: false,
          }); // Generate JSON table content，wb.Sheets Get the data of the first sheet
          const excellist = []; // Clear received data
          // Edit data
          for (var i = 0; i < ws.length; i++) {
            excellist.push(ws[i]);
          } // At this point, you get an array containing objects that need to be processed
          this.excellist = excellist;
          this.excellist.map((element) => {
            const data = {
              productName: element['Tên sản phẩm']
                ? element['Tên sản phẩm']
                : '',
              productCode: element['Mã sản phẩm'] ? element['Mã sản phẩm'] : '',
              discountAmount: element['Chiết khấu']
                ? element['Chiết khấu']
                : '',
            };
            this.importItems.push(data);
          });
        } catch (e) {
          return alert('Read failure!');
        }
      };
      fileReader.readAsBinaryString(files);
    },
    clearFiles() {
      this.$refs['file-input'].reset();
    },
    selectFileFor(event) {
      this.importItemFor = [];
      const files = event.target.files[0];
      this.fileFor = files;
      if (!files) {
        return;
      } else if (!/\.(xls|xlsx)$/.test(files.name.toLowerCase())) {
        this.fileFor = null;
        this.clearFiles();
        return alert(
          'Định dạng của file tải lên không chính xác. Vui lòng tải lên file có đuôi .xls hoặc .xlsx'
        );
      }
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const XLSX = xlsx;
          const workbook = XLSX.read(data, {
            type: 'binary',
          });
          const wsname = workbook.SheetNames[0]; // Take the first sheet，wb.SheetNames[0] :Take the name of the first sheet in the sheets
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            blankRows: false,
          }); // Generate JSON table content，wb.Sheets Get the data of the first sheet
          const excellist = []; // Clear received data
          // Edit data
          for (var i = 0; i < ws.length; i++) {
            excellist.push(ws[i]);
          } // At this point, you get an array containing objects that need to be processed
          this.excellistFor = excellist;
          this.excellistFor.map((element) => {
            const data = {
              productName: element['Tên sản phẩm']
                ? element['Tên sản phẩm']
                : '',
              productCode: element['Mã sản phẩm'] ? element['Mã sản phẩm'] : '',
              discountAmount: element['Chiết khấu']
                ? element['Chiết khấu']
                : '',
            };
            this.importItemFor.push(data);
          });
        } catch (e) {
          return alert('Read failure!');
        }
      };
      fileReader.readAsBinaryString(files);
    },
    showModalImportProductFor() {
      this.importItems = [];
      this.excellist = [];
      this.$refs['import-product-for-modal'].show();
    },
    validDataFor() {
      const data = {
        listProduct: this.importItemFor,
      };
      ApiService.post('accessories/valid-import-product', data)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            makeToastSuccess(message);
            response.data.data.forEach((element) => {
              const index = this.products.findIndex(
                (product) => product.productId === element.productId
              );
              if (index > -1) {
                makeToastFaile(`${element.productName} đã tồn tại`);
              } else {
                this.products.push(element);
              }
            });
            this.$refs['import-product-for-modal'].hide();
          } else {
            makeToastFaile(message);
          }
        })
        .catch((err) => {
          const message = err;
          makeToastFaile(message);
        });
    },
  },
};
</script>
